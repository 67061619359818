<template>
  <div class="mm-main-page">
    <div class="mm-seller-wrapper">
      <Header
        hide-search
        hide-city-change-button
      />
      <slot />
      <Footer />
      <GTM />
      <ModalsContainer />
    </div>
  </div>
</template>

<script lang="ts" setup>
import GTM from 'shared/components/GTM.vue';
import Header from 'components/layouts/default/Header.vue';
import Footer from 'components/layouts/default/Footer.vue';
import { useServerSsoAuth } from 'composables/useAuthHelper';
import { useMainTitle } from 'composables/useMainTitle';
import { useCheckInternalUser } from 'shared/composables/useCheckInternalUser';
import setAuthCookieHeaders from '../composables/setAuthCookieHeaders';

setAuthCookieHeaders();
await useDomainAuth();

useMainTitle();

await useServerSsoAuth();
await useSetServerUserInfo();
useSetClientUserInfo();
useCheckInternalUser();

</script>

<style lang="scss" scoped>
.mm-main-page {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  &.overflow {
    overflow: hidden;
  }
}

@media screen and (max-width: 1279px) {
  .mm-seller-wrapper {
    padding-bottom: 56px;
  }
}
</style>
